import {
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { ToggleableFeatures } from './feature-toggle';
import { FeatureToggleService } from './feature-toggle.service';

@Directive({
  selector: '[mspotFeatureToggleEnabled]',
  standalone: true,
})
export class FeatureToggleEnabledDirective implements AfterViewInit {
  @Input('mspotFeatureToggleEnabled') feature: ToggleableFeatures | undefined;

  private readonly _element = inject(ElementRef);
  private readonly _featureToggleService = inject(FeatureToggleService);

  ngAfterViewInit(): void {
    if (
      this.feature &&
      this._featureToggleService.isFeatureEnabled(this.feature)
    ) {
      return;
    }

    this._element.nativeElement.remove();
  }
}

@Directive({
  selector: '[mspotIfFeatureToggle]',
  standalone: true,
})
export class IfFeatureToggleDirective {
  private readonly _template = inject(TemplateRef<any>);
  private readonly _viewContainer = inject(ViewContainerRef);
  private readonly _featureToggleService = inject(FeatureToggleService);

  private _featureToggle: ToggleableFeatures | undefined;

  private _fallback: TemplateRef<any> | undefined = undefined;

  @Input() set mspotIfFeatureToggle(val: ToggleableFeatures | undefined) {
    this._featureToggle = val;
    this._handle();
  }

  @Input() set mspotIfFeatureToggleFallback(val: TemplateRef<any> | undefined) {
    this._fallback = val;
    this._handle();
  }

  private _handle() {
    this._viewContainer.clear();

    if (!this._featureToggle) {
      return;
    }

    const enabled = this._featureToggleService.isFeatureEnabled(
      this._featureToggle,
    );

    if (enabled) {
      this._viewContainer.createEmbeddedView(this._template, null, {
        index: 0,
      });

      return;
    }

    if (this._fallback) {
      this._viewContainer.createEmbeddedView(this._fallback, null, {
        index: 0,
      });
    }
  }
}
